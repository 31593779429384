.headerCalendar a{
    color: black;
    text-decoration: none;
    text-align: center;
    text-transform: capitalize;

}
.fc-header-toolbar .fc-toolbar-chunk {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Ajusta el espacio entre botones */
  }
  
  .fc-header-toolbar .fc-toolbar-chunk > :first-child {
    flex-basis: 100%; /* Fuerza a los elementos antes del marcador a tomar su propia línea */
  }